
// @ts-nocheck
import locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_en_json from "../locales/en.json";
import locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_it_json from "../locales/it.json";
import locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_de_json from "../locales/de.json";


export const localeCodes =  [
  "en",
  "it",
  "de"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.json", load: () => Promise.resolve(locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_en_json), cache: true }],
  "it": [{ key: "../locales/it.json", load: () => Promise.resolve(locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_it_json), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => Promise.resolve(locale__Users_lucaperusi_Documents_MARS_code_mars_website_nuxt_locales_de_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "language": "en-US",
      "files": [
        "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/en.json"
      ]
    },
    {
      "code": "it",
      "language": "it-IT",
      "files": [
        "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/it.json"
      ]
    },
    {
      "code": "de",
      "language": "de-DE",
      "files": [
        "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/de.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": true,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.myarstudio.cloud",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "it": "/",
      "de": "/",
      "en": "/"
    },
    "animation": {
      "it": "/animazione/",
      "de": "/animation/",
      "en": "/animation/"
    },
    "contacts": {
      "it": "/contattaci",
      "de": "/kontaktiere-uns",
      "en": "/contacts"
    },
    "examples/index": {
      "it": "/esempi",
      "de": "/beispielen",
      "en": "/examples"
    },
    "examples/antea-stress": {
      "it": "/esempi/antea-stress",
      "de": "/beispielen/antea-stress",
      "en": "/examples/antea-stress"
    },
    "examples/braun": {
      "it": "/esempi/braun",
      "de": "/beispielen/braun",
      "en": "/examples/braun"
    },
    "examples/console": {
      "it": "/esempi/console",
      "de": "/beispielen/console",
      "en": "/examples/console"
    },
    "examples/form-armchair": {
      "it": "/esempi/form-armchair",
      "de": "/beispielen/form-armchair",
      "en": "/examples/form-armchair"
    },
    "examples/onestring": {
      "it": "/esempi/onestring",
      "de": "/beispielen/onestring",
      "en": "/examples/onestring"
    },
    "examples/spring-chair": {
      "it": "/esempi/spring-chair",
      "de": "/beispielen/spring-chair",
      "en": "/examples/spring-chair"
    },
    "features": {
      "it": "/funzionalita",
      "de": "/eigenschaften",
      "en": "/features"
    },
    "how-to-start": {
      "it": "/come-iniziare",
      "de": "/wie-man-anfangt",
      "en": "/how-to-start"
    },
    "platform": {
      "it": "/piattaforma",
      "de": "/plattform",
      "en": "/platform"
    },
    "pricing": {
      "it": "/prezzi",
      "de": "/preisen",
      "en": "/pricing"
    },
    "support": {
      "it": "/assistenza",
      "de": "/support",
      "en": "/support"
    },
    "viewer-3d-ar": {
      "it": "/visualizzatore-3d-ar",
      "de": "/3d-ar-viewer",
      "en": "/viewer-3d-ar"
    },
    "starter-kit/index": {
      "en": "/starter-kit/"
    },
    "starter-kit/_subject": {
      "en": "/starter-kit/:subject/ "
    },
    "free-demo": {
      "it": "/demo-gratuita/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-US",
    "files": [
      {
        "path": "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/en.json"
      }
    ]
  },
  {
    "code": "it",
    "language": "it-IT",
    "files": [
      {
        "path": "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/it.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de-DE",
    "files": [
      {
        "path": "/Users/lucaperusi/Documents/MARS/code/mars-website-nuxt/locales/de.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
